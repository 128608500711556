import React from "react";
// import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Tony = () => {
  return (
    <Layout>
      <Head title="Tony Aguero" />
      <h3>Tony Aguero</h3>
      {/* <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        San Mateo, CA
      </h4> */}
      <p>
        <OutboundLink to="https://ello.co/tomo77">Ello</OutboundLink>
      </p>
      <p>
        <OutboundLink to="https://www.serigraphicsystems.com/">
          Prints
        </OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/14371915/ello-optimized-61a4edbb.jpg"
        alt="Tony Aguero art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/12725426/ello-optimized-e6fdd455.jpg"
        alt="Tony Aguero art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/12344666/ello-optimized-cb7b800f.jpg"
        alt="Tony Aguero art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/11213892/ello-optimized-17233b31.jpg"
        alt="Tony Aguero art"
      />
    </Layout>
  );
};

export default Tony;
